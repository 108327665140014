// extracted by mini-css-extract-plugin
export var addFile = "index-module--addFile--3LauT";
export var anotherIntro = "index-module--anotherIntro--1QPwc";
export var buttonStyle = "index-module--buttonStyle--AZXOc";
export var formBanner = "index-module--formBanner--YUqXw";
export var formContainer = "index-module--formContainer--m1kCc";
export var greatfulMessage = "index-module--greatfulMessage--Z-v3v";
export var imgContainer = "index-module--imgContainer--Xhz4n";
export var modal = "index-module--modal--GbTJs";
export var modalFooter = "index-module--modalFooter--YvSGp";
export var submitSuccess = "index-module--submitSuccess--3-ZYN";
export var trademark = "index-module--trademark--oFgD1";
export var upload = "index-module--upload--GsmZA";