import React, { useState, useRef, useEffect } from "react";

import { useSessionStorageState } from "ahooks";

import * as styles from "./index.module.scss";

import addIcon from "@src/images/complaints/addIcon.png";

import { Link } from "gatsby";

import {
  Input,
  Form,
  Radio,
  Space,
  Upload,
  Checkbox,
  Button,
  Col,
  Row,
  Modal,
  message as showMessage
} from "antd";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Header from "@components/header";

import Footer from "@components/footer";

import { trademarkRequest, uploadFileRequest } from "@src/utils/formrequest";
const Trademark = () => {
  const data = useStaticQuery(graphql`
    query {
      formBanner: file(relativePath: { eq: "complaints/formBanner.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallFormBanner: file(
        relativePath: { eq: "complaints/smallFormBanner.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      noticeImg: file(relativePath: { eq: "complaints/noticeImg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 170) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallNoticeImg: file(
        relativePath: { eq: "complaints/smallNoticeImg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      repeatNoticeImg: file(
        relativePath: { eq: "complaints/repeatNoticeImg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 170) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  //提取后端返回的文件的path，并且放在数组里面通过表单发送
  const [uploadFile, setUploadFile] = useState([]);

  //modal的显示隐藏相关
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRepeatModalVisible, setRepeatModalVisible] = useState(false);

  //表单填写完成了提交表单
  const onFinish = (value) => {
    const {
      role,
      name,
      email,
      phone_number,
      company_name,
      trademark_name,
      registration_place,
      registration_number,
      registration_service,
      infringement_target,
      infringement_url,
      added,
    } = value;

    // console.log(infringement_url);
    trademarkRequest({
      file: uploadFile,
      role,
      name,
      email,
      phone: phone_number,
      company_name,
      trademark: trademark_name,
      address: registration_place,
      registration_number,
      scope: registration_service,
      shop_url: infringement_target,
      commodity_url: infringement_url,
      remark: added,
    }).then(
      (res) => {
        //上传表单成功后的逻辑
        const {
          data: { resultCode, message },
        } = res;
        if (resultCode === 200) {
          setFields(null);
          setIsModalVisible(true);
        } else if (resultCode === 205) {
          setFields(null);
          setRepeatModalVisible(true);
        } else {
          showMessage.error(message)
          // console.log("发生了其他意料之外的错误", message);
        }
      },
      (error) => {
        // console.log("我发送请求失败了", error);
        showMessage.error('提交表单失败了', error)
      }
    );
  };

  //上传文件的相关操作
  const customRequest = ({ file, onSuccess, onError }) => {
    if (file.size / 1024 / 1024 < 10) {
      uploadFileRequest(file).then(
        (res) => {
          onSuccess(res, file);
          console.log("这是上传文件后上层拿到的数据》》》》》》》》》", res);
          console.log(
            res.data.data.path,
            "这是我拿到的需要存储的数据《《《《《《《"
          );
          setUploadFile([...uploadFile, res.data.data.path]);
        },
        (err) => {
          onError(err, file);
        }
      );
    }
  };


  //提取表单里面上传的文件
  const normFile = (e) => {
    const { file, fileList } = e;
    if (file.size / 1024 / 1024 > 10) {
      //如果大小大于10M
      fileList.pop()
      showMessage.error("上传的文件不能超过10M")
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //定义modal挂载的对象
  const trademark = useRef();

  //表单的数据回显功能使用ahooks 实现
  const [fields, setFields] = useSessionStorageState();
  const onFieldsChange = (_, allFields) => {
    setFields(allFields);
  };
  //在页面退出的时候清空sessionStorage里面存储的数据
  useEffect(() => {
    return () => {
      // console.log("执行了清理操作");
      setFields(undefined);
    };
  }, []);

  return (
    <div className={styles.trademark} ref={trademark}>
      <Header />
      {/* 表单 */}
      <div className={styles.formBanner}>
        <Row>
          <Col xs={0} sm={0} md={24}>
            <Img fluid={data.formBanner.childImageSharp.fluid} />
            <h1>商家商标投诉登记表</h1>
            <h4>
              填写人需保证所填信息真实有效,
              Shoprise将在收到反馈后3-7个工作日以邮件形式回复您。
            </h4>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <Img fluid={data.smallFormBanner.childImageSharp.fluid} />
            <h1>商家商标投诉登记表</h1>
            <h4>
              填写人需保证所填信息真实有效,
              <br />
              Shoprise将在收到反馈后3-7个工作日以邮件形式回复您。
            </h4>
          </Col>
        </Row>
      </div>
      <div className={styles.formContainer}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          fields={fields}
          onFieldsChange={onFieldsChange}
          initialValues={{
            role: "我是商标的拥有者"
          }}
        >
          {/* 用户身份 */}
          <Form.Item name='role'>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="我是商标的拥有者">我是商标的拥有者</Radio>
                <Radio value="我是商标所有者的授权代表">
                  我是商标所有者的授权代表
                </Radio>
                {/* <Radio value="其他">其他</Radio> */}
              </Space>
            </Radio.Group>
          </Form.Item>
          {/* 用户名字 */}
          <Form.Item
            label="姓名"
            name="name"
            rules={[
              { required: true, message: "请填写投诉人姓名" },
              {
                type: "string",
                min: 2,
                max: 20,
                message: "请输入符合规范的用户名",
              },
            ]}
          >
            <Input placeholder="请填写投诉人姓名" />
          </Form.Item>
          {/* 用户邮箱 */}
          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { required: true, message: "请输入投诉联系人邮箱" },
              { type: "email", message: "请输入正确形式的邮箱" },
            ]}
          >
            <Input placeholder="请填写投诉人联系邮箱，用于后续回复" />
          </Form.Item>
          {/* 用户电话 */}
          <Form.Item
            label="电话"
            name="phone_number"
            rules={[
              { required: true, message: "请输入投诉联系人电话" },
              {
                pattern:
                  /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input placeholder="请填写投诉人联系电话，用于后续回复" />
          </Form.Item>
          {/* 个人 / 公司名 */}
          <Form.Item
            label="个人 / 公司名"
            name="company_name"
            rules={[
              { required: true, message: "请填写个人 / 公司名" },
              {
                type: "string",
                min: 1,
                max: 50,
                message: "请输入长度为1-50个字符的个人/公司名",
              },
            ]}
          >
            <Input placeholder="请填写个人 / 公司名" />
          </Form.Item>
          {/* 商标名称 */}
          <Form.Item
            label="商标名称"
            name="trademark_name"
            rules={[
              { required: true, message: "请输入商标名称" },
              {
                type: "string",
                min: 1,
                max: 50,
                message: "请输入长度为1-50的商标名称",
              },
            ]}
          >
            <Input placeholder="请填写商标名称" />
          </Form.Item>
          {/* 商标注册地 */}
          <Form.Item
            label="商标注册地"
            name="registration_place"
            rules={[
              { required: true, message: "请输入商标注册地" },
              {
                type: "string",
                min: 1,
                max: 100,
                message: "请输入长度为1-100个字符的商标注册地",
              },
            ]}
          >
            <Input placeholder="请填写商标注册的国家地区" />
          </Form.Item>
          {/* 商标注册号 */}
          <Form.Item
            label="商标注册号"
            name="registration_number"
            rules={[
              { required: true, message: "请输入商标注册号" },
              {
                type: "string",
                min: 7,
                max: 20,
                message: "请输入长度为7-20个字符的注册号",
              },
            ]}
          >
            <Input placeholder="请填写您的商标注册号" />
          </Form.Item>
          {/* 商标注册涵盖的产品/服务 */}
          <Form.Item
            label="商标注册涵盖的产品/服务"
            name="registration_service"
            rules={[
              {
                required: true,
                message: "请输入商标注册涵盖的产品/服务",
              },
              {
                type: "string",
                min: 1,
                max: 100,
                message: "描述服务内容字数限制为100字",
              },
            ]}
          >
            <Input placeholder="请填写您的商标注册类型涵盖的产品/服务" />
          </Form.Item>
          {/* 侵权的店铺地址 */}
          <Form.Item
            label="侵权的店铺地址"
            name="infringement_target"
            rules={[
              {
                required: true,
                message: "请输入侵权的店铺地址",
              },
              {
                type: "url",
                message: "请输入正确的url地址",
              },
            ]}
          >
            <Input placeholder="请填写侵权的店铺地址" />
          </Form.Item>
          {/* 侵权作品/商品的链接 */}
          <Form.Item
            label="侵权作品/商品的链接"
            name="infringement_url"
            rules={[
              {
                required: true,
                message: "请输入侵权作品/商品的链接",
              },
            ]}
          >
            <Input.TextArea placeholder="请填写侵权作品/商品的链接，可填写多个，每个地址换行" />
          </Form.Item>
          {/* 其他材料补充 */}
          <Form.Item
            label="其他材料补充"
            name="another_material"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={styles.upload}
            extra={
              <div className={styles.anotherIntro}>
                上传能证明此网站侵权的材料证据（比如：图片，视频等，文件必须少于10MB）
              </div>
            }
            rules={[
              {
                required: false,
                message: "请上传其他材料",
              },
            ]}
          >
            <Upload.Dragger name="files" customRequest={customRequest}>
              <img src={addIcon} alt="addImg" />
            </Upload.Dragger>
          </Form.Item>
          {/* 补充说明 */}
          <Form.Item
            label="补充说明"
            name="added"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea placeholder="可在此补充说明这些证据是如何证明该网站违规" />
          </Form.Item>
          {/* 声明 */}
          <Form.Item
            label="声明"
            name="allchecked"
            rules={[
              {
                required: true,
                validator(rule, value = 1) {
                  if (value.length === 3) {
                    // callback();
                    return Promise.resolve();
                  }
                  return Promise.reject("请勾选全部");
                  // callback("请勾选以上声明");
                },
              },
            ]}
          >
            <Checkbox.Group>
              <Space direction="vertical">
                <Checkbox value="a">
                  本人本着诚信原则，此投诉内容未经版权所有者或其法定授权代理者授权。
                </Checkbox>

                <Checkbox value="b">
                  本人在伪证罪下宣誓，此投诉中的信息准确无误，并且本人是版权所有者或被法定授权代理者。
                </Checkbox>

                <Checkbox value="c">
                  本人确认，可以将此投诉的副本（包括上面提供的任何联系信息）提供给被投诉人。
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          {/* 提交按钮 */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "60px",
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "8px",
                marginTop: "40px",
              }}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}
        <Modal
          visible={isModalVisible}
          getContainer={trademark.current}
          maskClosable={false}
          onCancel={() => { setIsModalVisible(false) }}
          closable={false}
          centered
          footer={
            <div className={styles.modalFooter}>
              <Button
                className={styles.buttonStyle}
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                <Link to="/complaints/">确定</Link>
              </Button>
            </div>
          }
          className={styles.modal}
        >
          <div className={styles.imgContainer}>
            <Img fluid={data.noticeImg.childImageSharp.fluid} />
          </div>
          <div className={styles.submitSuccess}>提交成功</div>
          <div className={styles.greatfulMessage}>
            感谢您的反馈，我们会在审核资料后给您回复，请耐心等待。
          </div>
        </Modal>
        <Modal
          visible={isRepeatModalVisible}
          getContainer={trademark.current}
          closable={false}
          maskClosable={false}
          centered
          onCancel={() => {
            setRepeatModalVisible(false)
          }}
          footer={
            <div className={styles.modalFooter}>
              <Button
                className={styles.buttonStyle}
                onClick={() => {
                  setRepeatModalVisible(false);
                }}
              >
                <Link to="/complaints/">确定</Link>
              </Button>
            </div>
          }
          className={styles.modal}
        >
          <div className={styles.imgContainer}>
            <Img fluid={data.repeatNoticeImg.childImageSharp.fluid} />
          </div>
          {/* <div className={styles.submitSuccess}>您已经提交成功，无须再次提交</div> */}
          <div
            className={styles.greatfulMessage}
            style={{
              lineHeight: "80px",
            }}
          >
            您已经提交成功，无须再次提交
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Trademark;
